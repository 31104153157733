<template>
  <el-dialog v-bind="$attrs" v-on="$listeners">
    <div class="question-author">
      <div class="info-wrap">
        <el-image
          class="avatar"
          fit="cover"
          :src="detail.customerAuthAvatarPath"
        >
          <img :src="$defaultAvatarUrl" slot="error" />
        </el-image>
        <div class="base-info">
          <div class="name">
            {{ detail.customerAuthName || detail.customerName
            }}<span
              v-if="detail.authSlaveType"
              class="badge"
              :class="[authSlaveTypes[detail.authSlaveType].color]"
              >{{ authSlaveTypes[detail.authSlaveType].name }}</span
            >
          </div>
          <p v-if="detail.description">{{ detail.description }}</p>
        </div>
      </div>
      <div class="in-date">{{ detail.inDateStr }}</div>
    </div>
    <div class="question-content">
      <span class="badge red" v-if="detail.top"
        >[推荐{{ detail.weight }}]
      </span>
      <span class="badge yellow" v-if="detail.common">[常见问题] </span>
      <span v-html="detail.content"></span>
    </div>
    <div class="rel-estate" v-if="detail.estateName">
      <svg-icon icon-class="lou" class-name="red"></svg-icon>
      {{ detail.estateName }}
    </div>
    <div class="action-bar">
      <p class="tips">
        {{ detail.viewCount }}人看过 · {{ detail.forwardCount }}次转发
      </p>
      <div class="actions">
        <el-popconfirm
          confirm-button-text="好的"
          cancel-button-text="不用了"
          icon="el-icon-info"
          icon-color="red"
          title="确定删除吗？"
          @onConfirm="deleteQuestion"
        >
          <el-button slot="reference" type="text" style="margin-right: 10px;">
            删除问答
          </el-button>
        </el-popconfirm>
        <el-button
          type="text"
          @click="setFAQ"
          :disabled="!detail.hasAccepted && !detail.common"
          >{{ detail.common ? "取消" : "设为" }}“常见问答”</el-button
        >
        <el-dropdown
          ref="topDropdown"
          style="padding: 0 10px;"
          trigger="click"
          v-if="!detail.top"
        >
          <el-button type="text">
            推荐问答
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <div class="f-top-dropdown">
              <div class="title">推荐权重<span class="red">*</span></div>
              <el-input
                v-model.number="topNum"
                maxlength="3"
                placeholder="值越小越靠前"
              ></el-input>
              <div class="foot">
                <el-button size="mini" @click="$refs.topDropdown.hide()"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="recommendQuestion(true)"
                  >确定</el-button
                >
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button v-else type="text" @click="recommendQuestion(false)"
          >取消推荐</el-button
        >
        <el-button type="text" @click="togglePublishBox = !togglePublishBox"
          >帮他回答</el-button
        >
      </div>
    </div>
    <div class="publish-content" v-if="togglePublishBox">
      <el-input
        v-model="answerFormData.content"
        type="textarea"
        resize="none"
        :rows="6"
        placeholder="请填写回答内容"
      ></el-input>
      <div class="actions">
        <div class="x aic">
          <div class="label">关联认证用户<span class="red">*</span></div>
          <el-autocomplete
            style="width:260px"
            prefix-icon="el-icon-search"
            value-key="name"
            v-model="answerRelUserKeyword"
            :fetch-suggestions="getAuthUserList"
            :hide-loading="hideFetchUserLoading"
            placeholder="搜索“认证名称”或“用户昵称”"
            @select="handleUserSelect"
          >
            <div class="auth-user-option" slot-scope="{ item }">
              <el-image class="avatar" fit="cover" :src="item.authAvatarPath">
                <img :src="$defaultAvatarUrl" slot="error" />
              </el-image>
              <div class="base-info">
                <div class="name">
                  {{ item.authName
                  }}<span
                    v-if="item.authSlaveType"
                    class="badge"
                    :class="[authSlaveTypes[item.authSlaveType].color]"
                    >{{ item.authSlaveTypeName }}</span
                  >
                </div>
                <p v-if="item.description">{{ item.description }}</p>
              </div>
            </div>
          </el-autocomplete>
        </div>
        <el-button type="primary" @click="publishAnswer">提交回答</el-button>
      </div>
    </div>
    <div class="comment-list">
      <div class="title">全部{{ answerList.length }}条评论</div>
      <div class="empty center" v-if="!answerList.length">
        暂无评论~~
      </div>
      <div
        class="comment-item"
        v-for="(item, index) in answerList"
        :key="item.id"
        :class="{ accepted: item.accepted }"
      >
        <div class="head">
          <div class="author">
            <div class="info-wrap">
              <el-image class="avatar" fit="cover" :src="item.avatarPath">
                <img :src="$defaultAvatarUrl" slot="error" />
              </el-image>
              <div class="base-info">
                <div class="name">
                  {{ item.customerName
                  }}<span
                    v-if="item.authSlaveTypes"
                    class="badge"
                    :class="[authSlaveTypes[item.authSlaveType].color]"
                    >{{ authSlaveTypes[item.authSlaveType].name }}</span
                  >
                </div>
                <p v-if="item.description">{{ item.description }}</p>
              </div>
            </div>
          </div>
          <div class="zan">
            <svg-icon icon-class="dianzan"></svg-icon>{{ item.praiseCount }}
          </div>
          <div class="accepted-badge" v-if="item.accepted">
            <el-image
              src="https://fangyibao-res.oss-cn-hangzhou.aliyuncs.com/mini_program_image/icon_yicaina.png"
              fit="widthFix"
            >
            </el-image>
            已采纳
          </div>
        </div>
        <p class="body" v-html="item.content"></p>
        <div class="foot">
          <span class="color9">{{ item.inDateStr }}</span>
          <div>
            <el-popconfirm
              confirm-button-text="好的"
              cancel-button-text="不用了"
              icon="el-icon-info"
              icon-color="red"
              title="确定删除吗？"
              @onConfirm="deleteAnswer(item.id, index)"
            >
              <el-button
                slot="reference"
                type="text"
                style="margin-right: 10px;"
              >
                删除回答
              </el-button>
            </el-popconfirm>
            <el-button type="text" @click="acceptAnswer(item, index)"
              >{{ item.accepted ? "取消" : "设为" }}采纳</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  getAuthUserList,
  getQuestionDetail,
  publishAnswer,
  deleteQuestion,
  setFAQ,
  recommendQuestion,
  deleteAnswer,
  acceptAnswer
} from "@/api/appManage";
export default {
  props: {
    questionId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      authSlaveTypes: {
        1: {
          name: "地产达人",
          color: "green"
        },
        2: {
          name: "置业顾问",
          color: "blue"
        },
        3: {
          name: "PCN经纪",
          color: "orange"
        }
      },
      togglePublishBox: false, // 控制回答框显示
      topNum: null, //推荐问答权重值
      answerRelUserKeyword: "",
      hideFetchUserLoading: false,
      detail: {},
      answerList: [], //问题评论答复列表
      answerFormData: {
        //提交答复字段
        content: null,
        customerAuthId: null,
        customerAuthName: null
      }
    };
  },
  watch: {
    questionId(val, oldVal) {
      if (val === oldVal || !val) return;
      this.getQuestionDetail();
    }
  },
  methods: {
    async deleteAnswer(answerId, index) {
      // 删除问题答复
      const res = await deleteAnswer({ questionId: this.questionId, answerId });
      if (res) {
        this.getQuestionDetail();
        this.$emit("reRequest");
      }
    },
    async acceptAnswer({ accepted, id }, index) {
      // 采纳答复
      const res = await acceptAnswer({
        questionId: this.questionId,
        bizId: id,
        praiseStatus: !accepted
      });
      if (res) this.getQuestionDetail();
    },
    async setFAQ() {
      //设为常见问题
      const res = await setFAQ({
        common: !this.detail.common,
        questionId: this.questionId
      });
      if (res) {
        this.getQuestionDetail();
        this.$emit("reRequest");
      }
    },
    async recommendQuestion(top) {
      // 推荐问题
      const res = await recommendQuestion({
        weight: this.topNum,
        questionId: this.questionId,
        top
      });
      if (res) {
        this.getQuestionDetail();
        this.$emit("reRequest");
        this.$refs.topDropdown.hide();
        this.topNum = null;
      }
    },
    async deleteQuestion() {
      //删除问题
      const res = await deleteQuestion({ questionId: this.questionId });
      if (res) {
        this.$showSuccess("删除成功");
        this.$emit("update:visible", false);
        this.$emit("reRequest");
        this.getQuestionDetail();
      }
    },
    async publishAnswer() {
      //发布回复
      const { content, customerAuthId } = this.answerFormData;
      if (!customerAuthId) {
        this.$showError("请选择关联用户");
        return;
      }
      if (!content.trim().length) {
        this.$showError("请输入回答内容");
        return;
      }
      const res = await publishAnswer({
        ...this.answerFormData,
        bizId: this.questionId
      });
      if (res) {
        this.answerFormData.content = null;
        this.answerFormData.customerAuthId = null;
        this.answerRelUserKeyword = null;
        this.getQuestionDetail();
        this.$emit("reRequest");
      }
    },
    async getQuestionDetail() {
      const res = await getQuestionDetail({ id: this.questionId });
      if (res) {
        // 换行符替换成 br
        res.content = res.content.replace(/\n+/g, "<br>");
        this.detail = res;
        this.answerList = res.commentLRespVos.map(item => {
          item.content = item.content.replace(/\n+/g, "<br>");
          return item;
        });
      } else {
        this.detail = {};
        this.answerList = [];
      }
    },
    async handleUserSelect({ authName, id }) {
      //选择用户
      this.answerFormData.customerAuthId = id;
      this.answerFormData.customerAuthName = authName;
      this.answerRelUserKeyword = authName;
    },
    async getAuthUserList(keyword, callback) {
      //查询认证用户列表
      this.hideFetchUserLoading = true;
      if (!keyword || !keyword.trim()) return;
      this.hideFetchUserLoading = false;
      const res = await getAuthUserList({ authName: keyword });
      this.hideFetchUserLoading = true;
      callback(res || []);
    }
  }
};
</script>
<style lang="scss">
.auth-user-option {
  display: flex;
  height: 50px;
  padding: 5px 0;
  margin: 10px 0;
  .base-info {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .name {
      display: flex;
      align-items: center;
      line-height: 1;
      .badge {
        margin-left: 5px;
        font-size: 10px;
        padding: 2px 6px;
      }
    }
    p {
      font-size: 12px;
      color: #999;
    }
  }
  .in-date {
    color: #999;
  }
}
</style>

<style scoped lang="scss">
.green {
  color: $green;
  background-color: $greenBg;
}
.orange {
  color: $orange;
  background-color: $orangeBg;
}
.blue {
  color: $blue;
  background-color: $blueBg;
}
.empty {
  padding: 50px 0;
  color: #999;
}
.question-author {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .info-wrap {
    display: flex;
  }
  .base-info {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .name {
      display: flex;
      align-items: center;
      line-height: 1;
      .badge {
        margin-left: 5px;
        font-size: 10px;
        padding: 2px 6px;
      }
    }
    p {
      font-size: 12px;
      color: #999;
    }
  }
  .in-date {
    color: #999;
  }
}
.question-content {
  position: sticky;
  top: 0;
  z-index: 9;
  padding: 10px 20px;
  margin: 0 -20px;
  font-size: 16px;
  color: #333;
  background: #fff;
}
.rel-estate {
  margin-top: 10px;
  color: $red;
}
.action-bar {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .tips {
    color: #999;
  }
  .actions {
  }
}
.publish-content {
  ::v-deep {
    .el-textarea {
      .el-textarea__inner {
        background-color: #eee;
        border: none;
      }
    }
  }
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      margin-right: 10px;
    }
  }
}
.comment-list {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  .title {
    font-size: 16px;
  }
  .comment-item {
    padding: 20px 0 10px 0;
    position: relative;
    & + .comment-item {
      border-top: 1px solid #eee;
    }
    &.accepted {
      padding: 20px 40px 0 40px;
      margin: 20px -20px 0 -20px;
      background-color: $redBg;
    }
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .accepted-badge {
        position: absolute;
        left: 250px;
        top: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: $orange;
        ::v-deep {
          .el-image {
            width: 20px;
          }
        }
      }
      .author {
        @extend .question-author;
      }
      .zan {
        color: #999;
        svg {
          margin-right: 5px;
        }
      }
    }
    .body {
      padding: 20px 0;
    }
    .foot {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
