var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({}, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "question-author"
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('el-image', {
    staticClass: "avatar",
    attrs: {
      "fit": "cover",
      "src": _vm.detail.customerAuthAvatarPath
    }
  }, [_c('img', {
    attrs: {
      "slot": "error",
      "src": _vm.$defaultAvatarUrl
    },
    slot: "error"
  })]), _c('div', {
    staticClass: "base-info"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.detail.customerAuthName || _vm.detail.customerName)), _vm.detail.authSlaveType ? _c('span', {
    staticClass: "badge",
    class: [_vm.authSlaveTypes[_vm.detail.authSlaveType].color]
  }, [_vm._v(_vm._s(_vm.authSlaveTypes[_vm.detail.authSlaveType].name))]) : _vm._e()]), _vm.detail.description ? _c('p', [_vm._v(_vm._s(_vm.detail.description))]) : _vm._e()])], 1), _c('div', {
    staticClass: "in-date"
  }, [_vm._v(_vm._s(_vm.detail.inDateStr))])]), _c('div', {
    staticClass: "question-content"
  }, [_vm.detail.top ? _c('span', {
    staticClass: "badge red"
  }, [_vm._v("[推荐" + _vm._s(_vm.detail.weight) + "] ")]) : _vm._e(), _vm.detail.common ? _c('span', {
    staticClass: "badge yellow"
  }, [_vm._v("[常见问题] ")]) : _vm._e(), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.detail.content)
    }
  })]), _vm.detail.estateName ? _c('div', {
    staticClass: "rel-estate"
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": "lou",
      "class-name": "red"
    }
  }), _vm._v(" " + _vm._s(_vm.detail.estateName) + " ")], 1) : _vm._e(), _c('div', {
    staticClass: "action-bar"
  }, [_c('p', {
    staticClass: "tips"
  }, [_vm._v(" " + _vm._s(_vm.detail.viewCount) + "人看过 · " + _vm._s(_vm.detail.forwardCount) + "次转发 ")]), _c('div', {
    staticClass: "actions"
  }, [_c('el-popconfirm', {
    attrs: {
      "confirm-button-text": "好的",
      "cancel-button-text": "不用了",
      "icon": "el-icon-info",
      "icon-color": "red",
      "title": "确定删除吗？"
    },
    on: {
      "onConfirm": _vm.deleteQuestion
    }
  }, [_c('el-button', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "slot": "reference",
      "type": "text"
    },
    slot: "reference"
  }, [_vm._v(" 删除问答 ")])], 1), _c('el-button', {
    attrs: {
      "type": "text",
      "disabled": !_vm.detail.hasAccepted && !_vm.detail.common
    },
    on: {
      "click": _vm.setFAQ
    }
  }, [_vm._v(_vm._s(_vm.detail.common ? "取消" : "设为") + "“常见问答”")]), !_vm.detail.top ? _c('el-dropdown', {
    ref: "topDropdown",
    staticStyle: {
      "padding": "0 10px"
    },
    attrs: {
      "trigger": "click"
    }
  }, [_c('el-button', {
    attrs: {
      "type": "text"
    }
  }, [_vm._v(" 推荐问答 ")]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('div', {
    staticClass: "f-top-dropdown"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("推荐权重"), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('el-input', {
    attrs: {
      "maxlength": "3",
      "placeholder": "值越小越靠前"
    },
    model: {
      value: _vm.topNum,
      callback: function callback($$v) {
        _vm.topNum = _vm._n($$v);
      },
      expression: "topNum"
    }
  }), _c('div', {
    staticClass: "foot"
  }, [_c('el-button', {
    attrs: {
      "size": "mini"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.topDropdown.hide();
      }
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "mini"
    },
    on: {
      "click": function click($event) {
        return _vm.recommendQuestion(true);
      }
    }
  }, [_vm._v("确定")])], 1)], 1)])], 1) : _c('el-button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function click($event) {
        return _vm.recommendQuestion(false);
      }
    }
  }, [_vm._v("取消推荐")]), _c('el-button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function click($event) {
        _vm.togglePublishBox = !_vm.togglePublishBox;
      }
    }
  }, [_vm._v("帮他回答")])], 1)]), _vm.togglePublishBox ? _c('div', {
    staticClass: "publish-content"
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "resize": "none",
      "rows": 6,
      "placeholder": "请填写回答内容"
    },
    model: {
      value: _vm.answerFormData.content,
      callback: function callback($$v) {
        _vm.$set(_vm.answerFormData, "content", $$v);
      },
      expression: "answerFormData.content"
    }
  }), _c('div', {
    staticClass: "actions"
  }, [_c('div', {
    staticClass: "x aic"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("关联认证用户"), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('el-autocomplete', {
    staticStyle: {
      "width": "260px"
    },
    attrs: {
      "prefix-icon": "el-icon-search",
      "value-key": "name",
      "fetch-suggestions": _vm.getAuthUserList,
      "hide-loading": _vm.hideFetchUserLoading,
      "placeholder": "搜索“认证名称”或“用户昵称”"
    },
    on: {
      "select": _vm.handleUserSelect
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return _c('div', {
          staticClass: "auth-user-option"
        }, [_c('el-image', {
          staticClass: "avatar",
          attrs: {
            "fit": "cover",
            "src": item.authAvatarPath
          }
        }, [_c('img', {
          attrs: {
            "slot": "error",
            "src": _vm.$defaultAvatarUrl
          },
          slot: "error"
        })]), _c('div', {
          staticClass: "base-info"
        }, [_c('div', {
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(item.authName)), item.authSlaveType ? _c('span', {
          staticClass: "badge",
          class: [_vm.authSlaveTypes[item.authSlaveType].color]
        }, [_vm._v(_vm._s(item.authSlaveTypeName))]) : _vm._e()]), item.description ? _c('p', [_vm._v(_vm._s(item.description))]) : _vm._e()])], 1);
      }
    }], null, false, 3300799863),
    model: {
      value: _vm.answerRelUserKeyword,
      callback: function callback($$v) {
        _vm.answerRelUserKeyword = $$v;
      },
      expression: "answerRelUserKeyword"
    }
  })], 1), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.publishAnswer
    }
  }, [_vm._v("提交回答")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "comment-list"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("全部" + _vm._s(_vm.answerList.length) + "条评论")]), !_vm.answerList.length ? _c('div', {
    staticClass: "empty center"
  }, [_vm._v(" 暂无评论~~ ")]) : _vm._e(), _vm._l(_vm.answerList, function (item, index) {
    return _c('div', {
      key: item.id,
      staticClass: "comment-item",
      class: {
        accepted: item.accepted
      }
    }, [_c('div', {
      staticClass: "head"
    }, [_c('div', {
      staticClass: "author"
    }, [_c('div', {
      staticClass: "info-wrap"
    }, [_c('el-image', {
      staticClass: "avatar",
      attrs: {
        "fit": "cover",
        "src": item.avatarPath
      }
    }, [_c('img', {
      attrs: {
        "slot": "error",
        "src": _vm.$defaultAvatarUrl
      },
      slot: "error"
    })]), _c('div', {
      staticClass: "base-info"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(item.customerName)), item.authSlaveTypes ? _c('span', {
      staticClass: "badge",
      class: [_vm.authSlaveTypes[item.authSlaveType].color]
    }, [_vm._v(_vm._s(_vm.authSlaveTypes[item.authSlaveType].name))]) : _vm._e()]), item.description ? _c('p', [_vm._v(_vm._s(item.description))]) : _vm._e()])], 1)]), _c('div', {
      staticClass: "zan"
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": "dianzan"
      }
    }), _vm._v(_vm._s(item.praiseCount) + " ")], 1), item.accepted ? _c('div', {
      staticClass: "accepted-badge"
    }, [_c('el-image', {
      attrs: {
        "src": "https://fangyibao-res.oss-cn-hangzhou.aliyuncs.com/mini_program_image/icon_yicaina.png",
        "fit": "widthFix"
      }
    }), _vm._v(" 已采纳 ")], 1) : _vm._e()]), _c('p', {
      staticClass: "body",
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    }), _c('div', {
      staticClass: "foot"
    }, [_c('span', {
      staticClass: "color9"
    }, [_vm._v(_vm._s(item.inDateStr))]), _c('div', [_c('el-popconfirm', {
      attrs: {
        "confirm-button-text": "好的",
        "cancel-button-text": "不用了",
        "icon": "el-icon-info",
        "icon-color": "red",
        "title": "确定删除吗？"
      },
      on: {
        "onConfirm": function onConfirm($event) {
          return _vm.deleteAnswer(item.id, index);
        }
      }
    }, [_c('el-button', {
      staticStyle: {
        "margin-right": "10px"
      },
      attrs: {
        "slot": "reference",
        "type": "text"
      },
      slot: "reference"
    }, [_vm._v(" 删除回答 ")])], 1), _c('el-button', {
      attrs: {
        "type": "text"
      },
      on: {
        "click": function click($event) {
          return _vm.acceptAnswer(item, index);
        }
      }
    }, [_vm._v(_vm._s(item.accepted ? "取消" : "设为") + "采纳")])], 1)])]);
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }