<template>
  <div class="question-manage base-container">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">全部问答</h3>
        <p class="summary">推荐到“常见问题”时，必须要有已采纳答案；</p>
      </div>
    </div>
    <div class="filter">
      <el-input
        placeholder="问题关键词、提问者昵称"
        v-model="queryData.keywords"
        style="width: 320px;"
        class="f-search-bar"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
      <el-checkbox @change="handleCheckboxChange($event, 'common')"
        >常见问答</el-checkbox
      >
      <el-checkbox @change="handleCheckboxChange($event, 'top')"
        >推荐问答</el-checkbox
      >
    </div>
    <el-table class="body" :data="list">
      <el-table-column label="提问内容" width="500px">
        <template slot-scope="{ row }">
          <span class="red" v-if="row.top">[推荐{{ row.weight }}]</span>
          <span class="yellow" v-if="row.common">[常见问答]</span>
          {{ row.content }}
        </template>
      </el-table-column>
      <el-table-column label="关联楼盘">
        <template slot-scope="{ row }">
          {{ row.estateName || "-" }}
        </template>
      </el-table-column>
      <el-table-column label="提问者">
        <template slot-scope="{ row }">
          <p>{{ row.customerAuthName || row.customerName }}</p>
          <p class="color9" v-if="row.authSlaveType">
            {{ authSlaveTypes[row.authSlaveType] }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="浏览/转发">
        <template slot-scope="{ row }">
          {{ row.viewCount }} / {{ row.forwardCount }}
        </template>
      </el-table-column>
      <el-table-column label="提问时间" prop="inDateStr"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-button type="text" @click="showQuestionManage(row)"
            >查看详情({{ row.commentCount }})</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageNumChange"
      :current-page.sync="queryData.pageNum"
      :page-sizes="pageSizes"
      :page-size="queryData.pageSize"
      layout="sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <manage-question-dialog
      class="f-dialog"
      title="问答详情"
      width="700px"
      :close-on-click-modal="false"
      :visible.sync="toggleManageDialog"
      :questionId="activeQuestionId"
      @reRequest="getQuestionList"
    ></manage-question-dialog>
  </div>
</template>

<script>
import ManageQuestionDialog from "../_components/manageQuestion";
import { getQuestionList } from "@/api/appManage";
export default {
  components: {
    ManageQuestionDialog
  },
  data() {
    return {
      toggleManageDialog: false,
      pageSizes: [5, 10, 20, 30, 50],
      authSlaveTypes: {
        //认证类型
        1: "地产达人",
        2: "置业顾问",
        3: "PCN经纪"
      },
      queryData: {
        pageNum: 1,
        pageSize: 10,
        keywords: "",
        top: null,
        common: null
      },
      activeQuestionId: null, //当前操作问题的id
      total: 0, //列表总数
      list: [] //问题列表
    };
  },
  created() {
    this.getQuestionList();
  },
  methods: {
    handleCheckboxChange(bool, key) {
      // 筛选推荐或者常见问答
      this.queryData[key] = bool || null;
      this.search();
    },
    search() {
      this.pageNumChange(1);
    },
    showQuestionManage(row) {
      this.toggleManageDialog = true;
      this.activeQuestionId = row.id;
    },
    pageSizeChange(num) {
      this.queryData.pageSize = num;
      this.getQuestionList();
    },
    pageNumChange(num) {
      this.queryData.pageNum = num;
      this.getQuestionList();
    },
    async getQuestionList() {
      //获取问题列表
      const res = await getQuestionList(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.question-manage {
  .filter {
    display: flex;
    align-items: center;
    padding-top: 20px;
    ::v-deep {
      .el-input {
        margin-right: 20px;
      }
    }
  }
}
</style>
