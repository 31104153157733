var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "question-manage base-container"
  }, [_vm._m(0), _c('div', {
    staticClass: "filter"
  }, [_c('el-input', {
    staticClass: "f-search-bar",
    staticStyle: {
      "width": "320px"
    },
    attrs: {
      "placeholder": "问题关键词、提问者昵称"
    },
    model: {
      value: _vm.queryData.keywords,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "keywords", $$v);
      },
      expression: "queryData.keywords"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1), _c('el-checkbox', {
    on: {
      "change": function change($event) {
        return _vm.handleCheckboxChange($event, 'common');
      }
    }
  }, [_vm._v("常见问答")]), _c('el-checkbox', {
    on: {
      "change": function change($event) {
        return _vm.handleCheckboxChange($event, 'top');
      }
    }
  }, [_vm._v("推荐问答")])], 1), _c('el-table', {
    staticClass: "body",
    attrs: {
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "提问内容",
      "width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.top ? _c('span', {
          staticClass: "red"
        }, [_vm._v("[推荐" + _vm._s(row.weight) + "]")]) : _vm._e(), row.common ? _c('span', {
          staticClass: "yellow"
        }, [_vm._v("[常见问答]")]) : _vm._e(), _vm._v(" " + _vm._s(row.content) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "关联楼盘"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(row.estateName || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "提问者"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c('p', [_vm._v(_vm._s(row.customerAuthName || row.customerName))]), row.authSlaveType ? _c('p', {
          staticClass: "color9"
        }, [_vm._v(" " + _vm._s(_vm.authSlaveTypes[row.authSlaveType]) + " ")]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "浏览/转发"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(row.viewCount) + " / " + _vm._s(row.forwardCount) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "提问时间",
      "prop": "inDateStr"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.showQuestionManage(row);
            }
          }
        }, [_vm._v("查看详情(" + _vm._s(row.commentCount) + ")")])];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.queryData.pageNum,
      "page-sizes": _vm.pageSizes,
      "page-size": _vm.queryData.pageSize,
      "layout": "sizes, prev, pager, next, jumper",
      "total": _vm.total
    },
    on: {
      "size-change": _vm.pageSizeChange,
      "current-change": _vm.pageNumChange,
      "update:currentPage": function updateCurrentPage($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:current-page": function updateCurrentPage($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      }
    }
  }), _c('manage-question-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "title": "问答详情",
      "width": "700px",
      "close-on-click-modal": false,
      "visible": _vm.toggleManageDialog,
      "questionId": _vm.activeQuestionId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleManageDialog = $event;
      },
      "reRequest": _vm.getQuestionList
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-mol"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("全部问答")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v("推荐到“常见问题”时，必须要有已采纳答案；")])])]);

}]

export { render, staticRenderFns }