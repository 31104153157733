import request from "@/utils/request";

/**********************        看房专车           ***********************/

//预约列表
export const appointmentList = data =>
  request({
    url: `/api/v1/platform/appointment/web/list`,
    method: "get",
    data
  });

/**********************        找房委托           ***********************/

//获取全部委托
export const allEntrusts = data =>
  request({
    url: `/api/v1/platform/entrust/allEntrusts`,
    method: "get",
    data
  });

/**********************        文章管理           ***********************/

// 修改文章发布状态
export const changeArticleStatus = data =>
  request({
    url: `/api/v1/platform/web/article/changeArticleStatus`,
    data
  });

// 修改文章推荐状态
export const changeArticleTop = data =>
  request({
    url: `/api/v1/platform/web/article/changeArticleTop`,
    data
  });

// 删除文章
export const deleteArticle = data =>
  request({
    url: `/api/v1/platform/web/article/deleteArticle`,
    data
  });

// 编辑文章
export const editArticle = data =>
  request({
    url: `/api/v1/platform/web/article/editArticle`,
    data
  });

// 新建文章
export const insertArticle = data =>
  request({
    url: `/api/v1/platform/web/article/insertArticle`,
    data
  });

// 查询认证用户列表
export const getAuthUserList = data =>
  request({
    url: `/api/v1/platform/web/article/getAuthList`,
    data
  });

// 查询楼盘文章列表
export const getEstateArticleManageList = data =>
  request({
    url: `/api/v1/platform/web/article/getEstateArticleManageList`,
    data
  });

// 查询平台文章列表
export const getPlatformArticleManageList = data =>
  request({
    url: `/api/v1/platform/web/article/getPlatformArticleManageList`,
    data
  });

// 获取文章详情
export const getArticleDetail = data =>
  request({
    url: `/api/v1/platform/web/article/getArticleDetail`,
    data
  });

// 修改文章访问量
export const editArticleViewCount = data =>
  request({
    url: `/api/v1/platform/web/article/${data.articleId}/viewcount/${data.viewCount}`,
    data
  });

/***************************      直播管理       ***************************/

// 分页查询平台直播列表
export const getLiveManagePage = data =>
  request({
    url: `/api/v1/platform/web/live/getLiveManagePage`,
    data
  });

// 修改直播间推荐状态
export const editLiveRecommendStatus = data =>
  request({
    url: `/api/v1/platform/web/live/editLiveRecommendStatus`,
    data
  });

// 删除直播
export const deleteLive = data =>
  request({
    url: `/api/v1/platform/web/live/deleteLive`,
    data
  });

/***************************      广告管理       ***************************/

// 修改广告上架状态
export const changeAdvertStatus = data =>
  request({
    url: `/api/v1/platform/web/advert/changeAdvertStatus`,
    data
  });

// 删除广告
export const deleteAdvert = data =>
  request({
    url: `/api/v1/platform/web/advert/deleteAdvert`,
    data
  });

// 编辑广告
export const editAdvert = data =>
  request({
    url: `/api/v1/platform/web/advert/editAdvert`,
    data
  });

// 查询广告管理列表
export const getAdvertManageList = data =>
  request({
    url: `/api/v1/platform/web/advert/getAdvertManageList`,
    data
  });

// 查询广告类型树
export const getAdvertTypeTree = data =>
  request({
    url: `/api/v1/platform/web/advert/getAdvertTypeTree`,
    data
  });

// 新增广告
export const insertAdvert = data =>
  request({
    url: `/api/v1/platform/web/advert/insertAdvert`,
    data
  });

/***************************      挑房管理       ***************************/

// 分页查询平台挑房管理列表
export const getPickHouseManagePage = data =>
  request({
    url: `/api/v1/platform/web/pick/house/getPickHouseManagePage`,
    data
  });

// 修改挑房状态
export const changePickHouseStatus = data =>
  request({
    url: `/api/v1/platform/web/pick/house/changePickHouseStatus`,
    data
  });

/***************************      榜单管理       ***************************/

// 榜单规则列表查询
export const getRulePage = data =>
  request({
    url: `/api/v1/platform/estate/rank/getRulePage`,
    method: "post",
    data
  });

// 新增榜单规则
export const insertRule = data =>
  request({
    url: `/api/v1/platform/estate/rank/insertRule`,
    method: "post",
    data
  });

// 更新榜单规则
export const updateRule = data =>
  request({
    url: `/api/v1/platform/estate/rank/updateRule`,
    method: "post",
    data
  });

// 删除榜单规则
export const deleteRule = data =>
  request({
    url: `/api/v1/platform/estate/rank/deleteRule`,
    method: "post",
    data
  });

// 获取榜单规则详情
export const getRuleDetail = data =>
  request({
    url: `/api/v1/platform/estate/rank/getRuleDetail`,
    method: "post",
    data
  });

/***************************      问答管理       ***************************/

// 回答采纳/取消采纳
export const acceptAnswer = params =>
  request({
    url: `/api/v1/platform/question/accepted`,
    method: "post",
    params
  });

// 删除问题
export const deleteQuestion = params =>
  request({
    url: `/api/v1/platform/question/delete`,
    method: "post",
    params
  });

// 删除回复
export const deleteAnswer = params =>
  request({
    url: `/api/v1/platform/question/delete/answer`,
    method: "post",
    params
  });

// 问答详情
export const getQuestionDetail = params =>
  request({
    url: `/api/v1/platform/question/detail`,
    method: "get",
    params
  });

// 问答列表
export const getQuestionList = params =>
  request({
    url: `/api/v1/platform/question/page`,
    method: "get",
    params
  });

// 推荐问答
export const recommendQuestion = params =>
  request({
    url: `/api/v1/platform/question/recommend`,
    method: "post",
    params
  });

// 提交回答
export const publishAnswer = data =>
  request({
    url: `/api/v1/platform/question/submit/answer`,
    method: "post",
    data
  });

// 设置“常见问答”
export const setFAQ = params =>
  request({
    url: `/api/v1/platform/question/common`,
    method: "post",
    params
  });
